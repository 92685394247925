import React from 'react';

import { useDynamicPagePath } from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query';

export const useDynamicPagePathChange = (onDynamicPagePathChange: () => void) => {
    const dynamicPagePath = useDynamicPagePath();
    const dynamicPagePathRef = React.useRef(dynamicPagePath);

    const handleDynamicPagePathChange = React.useCallback(() => {
        if (dynamicPagePath === dynamicPagePathRef.current) {
            return;
        }

        onDynamicPagePathChange();
    }, [dynamicPagePath, onDynamicPagePathChange]);
    React.useEffect(handleDynamicPagePathChange, [handleDynamicPagePathChange]);
};
