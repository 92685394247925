import type {
    AccommodationFilterType,
    MapAccommodationFilterType,
} from '@/features/accommodation-filter/accommodation-filter-type';
import type { AccommodationTilesV1_jsonld_page_read } from '@/features/accommodation-tiles/accommodation-tiles-v1/accommodation-tiles-v1-type';
import type { MapDataV5_jsonld_page_read } from '@/features/map/map-data-v5/map-data-v5-type';

import React from 'react';

import useComponentDataByIri from '@/core/features/a-component/hooks/use-component-data-by-iri';
import { useDynamicPagePathChange } from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-path-change';
import { atom, useAtom, useAtomValue, useSetAtom } from '@/core/features/store/atom-store';
import { useFilterOverlayToggle } from '@/features/filter/filter-data/filter-state';
import {
    getSelectedFilterCount,
    hasNonDefaultFilterOptionsSelected,
} from '@/features/filter/filter-data/service/filter-data-service';

export const accommodationFiltersAtom = atom<AccommodationFilterType | MapAccommodationFilterType | null>(null);
const accommodationFiltersTargetComponentIriAtom = atom<null | string>(null);

export const useHydrateAccommodationFilterTargetAtom = (initialTarget: null | string) => {
    const { isOverlayOpen } = useFilterOverlayToggle({ overlayKey: 'accommodation-filter-overlay' });
    const { isOverlayOpen: isMapOverlayOpen } = useFilterOverlayToggle({
        overlayKey: 'accommodation-filter-overlay-map',
    });
    const isAccommodationFilterOverlayOpen = isOverlayOpen || isMapOverlayOpen;

    const [accommodationFiltersTarget, setAccommodationFiltersTarget] = useAtom(
        accommodationFiltersTargetComponentIriAtom,
    );

    const { data, isLoading } = useComponentDataByIri<
        AccommodationTilesV1_jsonld_page_read | MapDataV5_jsonld_page_read
    >(isAccommodationFilterOverlayOpen ? accommodationFiltersTarget : null, {
        queryName: 'accommodationFiltersTarget',
    });

    React.useEffect(() => {
        setAccommodationFiltersTarget(initialTarget);
    }, [initialTarget, setAccommodationFiltersTarget]);

    const setAccommodationFilters = useSetAtom(accommodationFiltersAtom);

    /**
     * Effect: hydrate accommodation filters from component data
     */
    React.useEffect(() => {
        if (!isLoading && data?.attributes?.accommodationFilters) {
            setAccommodationFilters(data.attributes.accommodationFilters);
        }
    }, [data, isLoading, setAccommodationFilters]);

    // cleanup state on url path change
    useDynamicPagePathChange(() => setAccommodationFilters(null));
};

export const useAccommodationFilterState = () => {
    const accommodationFilters = useAtomValue(accommodationFiltersAtom);

    const selectedFilterCount = React.useMemo(
        () => getSelectedFilterCount(accommodationFilters?.groups),
        [accommodationFilters?.groups],
    );

    const hasNonDefaultAccommodationFilterOptionSelected = React.useMemo(
        () => hasNonDefaultFilterOptionsSelected(accommodationFilters?.groups ?? []),
        [accommodationFilters?.groups],
    );

    return {
        accommodationFilters,
        hasNonDefaultAccommodationFilterOptionSelected,
        selectedFilterCount,
    };
};
